import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_PPV_MAINTAIN_POSTINGS } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-assign-global-portfolio" }, [_c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header-container mb-3 px-4 pt-2 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])])], 1)], 1), _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("div", { staticClass: "card p-3 mx-5" }, [_c("div", { staticClass: "header-title" }, [_vm._v("Filter By")]), _c("a-row", { attrs: { "gutter": 24, "type": "flex", "jusify": "end" } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Journal", "placeholder": "Type Journal", "form-item": "" }, on: { "change": function($event) {
      return _vm.onInputFilterChange($event, "Journal");
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Line", "placeholder": "Type Journal Line", "rules": "number", "form-item": "" }, on: { "change": function($event) {
      return _vm.onInputFilterChange($event, "JournalLine");
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "placeholder": "Select Segment5", "data-source": _vm.segments, "label": "Segment5", "source": "segment5", "source-label": "segment5Desc", "form-item": "" }, on: { "change": function($event) {
      return _vm.onFilterChange($event, "Segment5");
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Item", "placeholder": "Type Item", "form-item": "" }, on: { "change": function($event) {
      return _vm.onInputFilterChange($event, "Item");
    } } })], 1)], 1), _c("a-row", { attrs: { "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center", attrs: { "span": 4 } }, [_c("span", { staticClass: "neutral-10--text mr-2" }, [_vm._v("Show Blank Items Only?")]), _c("a-checkbox", { attrs: { "default-checked": false, "align": "center", "width": 85 }, on: { "change": function($event) {
      return _vm.onFilterChange($event.target.checked, "OnlyBlankItems");
    } } })], 1), _c("a-col", { staticClass: "d-flex justify-content-end", attrs: { "span": 20 } }, [_c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
      return handleSubmit(_vm.onApplyFilterClick);
    } } }, [_vm._v("Apply Filter")])], 1)], 1)], 1)];
  } }]) }), _c("list-composer", { attrs: { "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "customButton", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddNewClick } }, [_vm._v("Add New")])];
  }, proxy: true } : null, { key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Maintain Postings for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "default-active-first-option": "", "value": _vm.selectedCountry ? _vm.selectedCountry : "AU" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) && record.locked === 0 ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) && record.locked === 0 && record.lineSplitSeq === 0 ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Split")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "copy", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onSplit(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "Company", attrs: { "data-index": "company", "title": "Company", "sorter": true } }), _c("text-field", { key: "Journal", attrs: { "data-index": "journal", "title": "Journal", "sorter": true } }), _c("text-field", { key: "JournalLine", attrs: { "data-index": "journalLine", "title": "Journal Line", "sorter": true } }), _c("text-field", { key: "JournalDesc", attrs: { "data-index": "journalDesc", "title": "JournalDesc", "sorter": true } }), _c("text-field", { key: "LineSplitSeq", attrs: { "data-index": "lineSplitSeq", "title": "Split Seq", "sorter": true } }), _c("text-field", { key: "Adjustment", attrs: { "data-index": "adjustment", "title": "Adjustment", "sorter": true } }), _c("text-field", { key: "Segment5", attrs: { "data-index": "segment5", "title": "Segment5", "sorter": true } }), _c("text-field", { key: "OtherRefFields", attrs: { "data-index": "otherRefFields", "title": "Other Reference Fields", "sorter": true } }), _c("text-field", { key: "CostType", attrs: { "data-index": "costType", "title": "Cost Type", "sorter": true } }), _c("text-field", { key: "Item", attrs: { "data-index": "item", "title": "Item", "sorter": true } }), _c("text-field", { key: "Facility", attrs: { "data-index": "facility", "title": "Facility", "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var ListMaintainPostings_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".list-assign-global-portfolio .country-filter[data-v-11adc7f4]{min-width:160px}.list-assign-global-portfolio .header-container[data-v-11adc7f4]{background-color:#fff}.list-assign-global-portfolio .header-title[data-v-11adc7f4]{font-style:normal;font-weight:500;font-size:16px;margin-bottom:16px;color:#272d35}.list-assign-global-portfolio .title[data-v-11adc7f4]{font-style:normal;font-weight:500;font-size:20px;line-height:28px}.list-assign-global-portfolio .card[data-v-11adc7f4]{background:#ffffff;border:1px solid #eaedf0}\n")();
const __vue2_script$1 = {
  name: "ListMaintainPostings",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      segments: [],
      title: "",
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  created() {
    this.getCountries();
    this.getSegment5();
    this.crud.setQueryString("OnlyBlankItems", false);
    this.crud.setQueryString("CountryID", this.countryIdAU);
    this.getTitle();
  },
  methods: {
    getSegment5() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/ppv-segment5`).then((res) => {
        let { data: segments } = res;
        segments = [...segments];
        this.segments = segments;
      });
    },
    getTitle() {
      this.axios.get(`${this.apiUrl}/web-analytics/gjs/title`).then((res) => {
        this.title = res.data.title;
      });
    },
    onSplit(record) {
      this.$router.push(`/web-analytics/maintain-postings/${record.id}/split?SRCGLKEY=${record.srcglkey}&Company=${record.company}&Journal=${record.journal}&JournalLine=${record.journalLine}`);
    },
    onAddNewClick() {
      this.$router.push("/web-analytics/maintain-postings/create");
    },
    onApplyFilterClick() {
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "11adc7f4", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListMaintainPostings = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.common.ppv-segment5", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.gjs", "edit-route": "/web-analytics/maintain-postings/:id", "page": _vm.page } }, [_c("list-maintain-postings")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListMaintainPostings
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPV_MAINTAIN_POSTINGS,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "maintain-postings",
          title: "Maintain Postings",
          path: "/web-analytics/maintain-postings"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
